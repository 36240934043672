.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
html {
  overflow-x: hidden;
}

body {
  font-family: "Fahkwang", sans-serif;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  font-size: 1.1vw;
  background: #f5f5f5;
  letter-spacing: 0.418px;
  max-width: 100%;
  box-sizing: border-box;
}
/* * {
  cursor: none !important;
} */
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #263f50;
  outline: 1px solid slategrey;
}

.carousel-container {
  display: flex;
  width: 100%;
}
.carousel-button-group {
  position: absolute;
}
/* html {
  scroll-behavior: smooth;
}
.card {
  position: sticky;
  top: 15em;
  transform-origin: center top;
  margin: 0.5em;
  height: 300px;
}
.card:nth-child(0) {
  transform: translateY(0px);
}
.card:nth-child(1) {
  transform: translateY(16px);
}
.card:nth-child(2) {
  transform: translateY(32px);
} */
